import { Col, Form, Input, Row, Select, DatePicker, Button } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableXuatKhoCTList from "./TableXuatKhoCTList";
import { PrinterOutlined } from "@ant-design/icons";

import {
  createNhapXuat,
  updateNhapXuat,
  getNhapXuatByManx,
} from "store/slices/sale/nhapxuatSlice";

import {
  getDmDoiTuong,
  getDmLoaiPhieuNhapXuat,
  getDmThuocVatTu,
} from "store/slices/sale/danhmucSlice";

import moment from "moment";
import {
  getXuatKhoCTByManx,
  setXuatKhoCTList,
} from "store/slices/sale/xuatkhoSlice";
import { guidEmpty } from "constants";

import SlipModalComponent from "components/SlipModalComponent";
import { DOCUMENT_TYPE_ID } from "constants";
import { initXuatKhoCt } from ".";

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export default function ModalXuatKho({
  visibleModal,
  setVisibleModal,
  loading,
  nhapXuatDetail,
  setXuatKhoId,
  xuatKhoId,
  reloadData,
  xuatKhoCTList = [],
  permitList = [],
  setMaKkxuat,
  maKkxuat,
  setNgayxuat,
  ngayxuat,
  idPhieu,
  setIdPhieu,
  openNotification,
  dmKkList,
  setIsPrinter,
  setPrintData,
}) {
  const dispatch = useDispatch();
  const { dmLoaiPhieuNhapXuatList, dmDoiTuongList, dmThuocVatTuList } =
    useSelector((state) => state.danhMucSale);
  useEffect(() => {
    dispatch(getDmDoiTuong());
    dispatch(getDmLoaiPhieuNhapXuat());
    dispatch(getDmThuocVatTu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [form] = Form.useForm();
  const childRef = useRef(null);
  useEffect(() => {
    if ((xuatKhoId === null || xuatKhoId === 0) && idPhieu === null) {
      return;
    }
    dispatch(
      getNhapXuatByManx({
        id: xuatKhoId ?? 0,
        idphieu: idPhieu ?? guidEmpty,
        onSuccess: (data) => {
          dispatch(
            getXuatKhoCTByManx({
              manx: data.data.manx,
              isNewCt: false,
            })
          );
        },
      })
    );
  }, [dispatch, xuatKhoId, idPhieu]);
  useEffect(() => {
    if (xuatKhoId === null || xuatKhoId === 0) {
      form.setFieldValue("ngay", moment());
      return;
    }
    form.setFieldsValue(nhapXuatDetail);
    form.setFieldValue("ngay", moment(nhapXuatDetail.ngay));
  }, [form, nhapXuatDetail, xuatKhoId]);

  const onFinish = (values) => {
    const payload = {
      sophieu: values.sophieu,
      makk: values.makk,
      makkgiao: values.makkgiao,
      makknhan: values.makknhan,
      maloaiphieu: values.maloaiphieu,
      madoituong: values.madoituong,
      ngay: values.ngay,
      nguoigiao: values.nguoigiao,
      nguoinhan: values.nguoinhan,
      thue: values.thue,
      mahoadon: values.mahoadon,
      sohoadon: values.sohoadon,
      ngayhoadon: values.ngayhoadon,
      loai: -1,
      daduyet: values.daduyet,
      manvduyet: values.manvduyet,
      manv: values.manv,
      cachtinhthue: values.cachtinhthue,
      ghichu: values.ghichu,
      luanchuyen: false,
      onSuccess: ({ data }) => {
        // Refresh data after add/update table
        if (xuatKhoId === null) {
          openNotification("success", "topRight", `Thêm phiếu thành công!`);
          if (data.data.Id && data.data.MaPhieu && childRef.current) {
            childRef.current.signedDocumentCreate(
              data.data.Id,
              data.data.MaPhieu
            );
          }

          setMaKkxuat(values.makk);
          setNgayxuat(values.ngay);
          setXuatKhoId(data.data.MaNX);
        } else {
          openNotification("success", "topRight", `Lưu phiếu thành công!`);
        }

        reloadData();
      },
    };
    if (xuatKhoId !== null) {
      payload.manx = xuatKhoId;
      dispatch(updateNhapXuat(payload));
    } else {
      dispatch(createNhapXuat(payload));
    }
  };

  const renderInfo = () => {
    return (
      <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
        <Row gutter={16}>
          <Col sm={8} md={8} lg={7}>
            <Form.Item name="sophieu" label="Số phiếu">
              <Input placeholder="Tự động" readOnly />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={9}>
            <Form.Item
              name="ngay"
              label="Ngày xuất"
              rules={[
                {
                  required: true,
                  message: "Ngày lập phiếu là bắt buộc",
                },
              ]}
            >
              <DatePicker
                showTime
                style={{ width: "100%" }}
                placeholder={"Ngày xuất"}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item
              name="maloaiphieu"
              label="Loại phiếu"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại phiếu",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmLoaiPhieuNhapXuatList?.filter((x) => x.maloai === 2)}
                placeholder="Chọn loại phiếu"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={24} md={24} lg={24}>
            <Form.Item
              name="makk"
              label="Kho xuất"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn kho xuất",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList?.filter((x) => x.maloai === 1)}
                placeholder="Chọn kho xuất"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={16} md={16} lg={16}>
            <Form.Item
              name="makkgiao"
              label="Đơn vị giao"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đơn vị giao",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList?.filter((x) => x.maloai === 5)}
                placeholder="Chọn kho đơn vị giao"
                onChange={(value) => {
                  const dvg = dmKkList.find((item) => value === item.value);
                  if (dvg !== null && dvg !== undefined) {
                    form.setFieldValue("nguoigiao", dvg.nguoilienhe);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="nguoigiao" label="Người giao">
              <Input placeholder="Nhập người giao" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={16} md={16} lg={16}>
            <Form.Item
              name="makknhan"
              label="Đơn vị nhận"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đơn vị nhận",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList}
                placeholder="Chọn kho đơn vị nhận"
                onChange={(value) => {
                  const dvn = dmKkList.find((item) => value === item.value);
                  if (dvn !== null && dvn !== undefined) {
                    form.setFieldValue("nguoinhan", dvn.nguoilienhe);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="nguoinhan" label="Người nhận">
              <Input placeholder="Nhập người nhận" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="madoituong" label="Đối tượng">
              <Select
                showSearch
                allowClear
                filterOption={filterOption}
                options={dmDoiTuongList}
                placeholder="Chọn đối tượng"
              />
            </Form.Item>
          </Col>
          <Col sm={16} md={16} lg={16}>
            <Form.Item name="ghichu" label="Ghi chú">
              <Input placeholder="Nhập ghi chú" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={0}>
            <Form.Item name="daduyet" label="Đã duyệt" hidden>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={0}>
            <Form.Item name="manvduyet" label="Mã nhân viên duyệt" hidden>
              <Input></Input>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Lưu
            </Button>
          </Col>
          <Col sm={12}>
            <Button
              title={"In phiếu"}
              type="primary"
              onClick={() => {
                if (xuatKhoId === null) {
                  openNotification("error", "topRight", `Phiếu không tồn tại!`);
                  return;
                }
                setIsPrinter(true);
                setPrintData({
                  idphieu: nhapXuatDetail.idphieu,
                  manx: nhapXuatDetail.manx,
                  sophieu: nhapXuatDetail.sophieu,
                });
              }}
              icon={<PrinterOutlined type="primary" />}
            >
              In
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <SlipModalComponent
      ref={childRef}
      maskClosable={false}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      width={1400}
      title={xuatKhoId === null ? "Thêm phiếu xuất kho" : "Sửa phiếu xuất kho"}
      objectId={nhapXuatDetail?.idphieu}
      objectType={"PhieuXuatKho"}
      documentTypeId={DOCUMENT_TYPE_ID.XuatHang}
      widthInfo={550}
      titleTLink="Phiếu xuất kho"
      descriptionTLink={nhapXuatDetail?.nhapKhoId}
      isVisibleModal={visibleModal}
      onRenderTab={[
        {
          label: "Thông tin chi tiết",
          children: (
            <TableXuatKhoCTList
              setXuatKhoId={setXuatKhoId}
              xuatKhoCTList={xuatKhoCTList}
              reloadData={() => reloadData()}
              setVisibleModal={setVisibleModal}
              visibleModal={visibleModal}
              dmThuocVatTuList={dmThuocVatTuList}
              permitList={permitList}
              xuatKhoId={xuatKhoId}
              maKkxuat={maKkxuat}
              ngayxuat={ngayxuat}
            />
          ),
        },
      ]}
      onSignReloadForm={(data) => {
        if (data.totalSigned === data.totalSigns) {
          dispatch(
            getNhapXuatByManx({
              id: xuatKhoId ?? 0,
              idphieu: idPhieu ?? guidEmpty,
            })
          );
          reloadData();
        }
      }}
      onRenderInfo={() => renderInfo()}
      onCancelModal={() => {
        setVisibleModal(false);
        form.resetFields();
        setXuatKhoId(null);
        setIdPhieu(null);
        dispatch(setXuatKhoCTList([initXuatKhoCt]));
        reloadData();
      }}
    />
  );
}
